import React from 'react';
import { Group, Box } from '@mantine/core';

export function Brand() {
    return (
        <Box
            sx={(theme) => ({
                paddingLeft: theme.spacing.xs,
                paddingRight: theme.spacing.xs,
                paddingBottom: theme.spacing.lg,
                borderBottom: `1px solid ${
                    theme.colorScheme === 'dark'
                        ? theme.colors.dark[4]
                        : theme.colors.gray[2]
                }`,
            })}
        >
            <Group position="apart">
                <img
                    src={require('../assets/phio_dark.png')}
                    alt="logo"
                    style={{ width: 124 }}
                />
            </Group>
        </Box>
    );
}

import Package from '../../package.json';
const hostedDomainsRegex = /(phio|demo|admin|api|staging|covid19admin|covid19)/;
const localHostRegex = /localhost/;
const localMacRegex = /localmac/; // used for testing in vitual machines
const ipRegex = /[\d]{1,3}.[\d]{1,3}.[\d]{1,3}.[\d]{1,3}:3000/;
const stagingRegex = /staging/;
const playgroundRegex = /(admin-playground|api-sandbox)/;

export const getHostName = () => {
    return window.location.hostname;
};

export const isLocalHost = () => {
    const hostName = getHostName();
    return (
        localHostRegex.test(hostName) ||
        localMacRegex.test(hostName) ||
        ipRegex.test(hostName)
    );
};

export const isStaging = () => {
    const hostName = getHostName();
    return stagingRegex.test(hostName) || playgroundRegex.test(hostName);
};

export const getHostedDomainName = () => {
    const hostName = getHostName();
    return hostName.match(hostedDomainsRegex);
};

export const isPlayground = () => {
    const hostName = getHostName();
    return playgroundRegex.test(hostName);
};

export const docVersion = () => {
    return Package.version;
};

import React from 'react';
import { Books } from 'tabler-icons-react';
import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

interface MainLinkProps {
    icon: React.ReactNode;
    color: string;
    label: string;
    path: string;
}

function MainLink({ icon, color, label, path }: MainLinkProps) {
    const navigate = useNavigate();
    return (
        <UnstyledButton
            sx={(theme) => ({
                display: 'block',
                width: '100%',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                color:
                    theme.colorScheme === 'dark'
                        ? theme.colors.dark[0]
                        : theme.black,

                '&:hover': {
                    backgroundColor:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[6]
                            : theme.colors.gray[0],
                },
            })}
            onClick={() => {
                navigate(path);
            }}
        >
            <Group>
                <ThemeIcon color={color} variant="light">
                    {icon}
                </ThemeIcon>
                <Text size="sm">{label}</Text>
            </Group>
        </UnstyledButton>
    );
}

const data = [
    {
        icon: <Books size={16} />,
        color: 'blue',
        label: 'API Reference',
        path: '/version-1-docs',
    },
];

interface Props {
    isMedicalDevice?: boolean;
}

export function MainLinks(props: Props) {
    const { isMedicalDevice } = props;
    const links = data.map((link) => (
        <MainLink
            {...link}
            path={isMedicalDevice ? `${link.path}-medical-device` : link.path}
            key={link.label}
        />
    ));
    return <div>{links}</div>;
}

import React, { useState } from 'react';
import {
    AppShell,
    Navbar,
    Header,
    Footer,
    Aside,
    Text,
    MediaQuery,
    Burger,
    useMantineTheme,
    Title,
    Divider,
    List,
    Group,
} from '@mantine/core';
import { Prism } from '@mantine/prism';
import { Brand } from '../components/Brand';
import { MainLinks } from '../components/NavigationLinks';
import SoftwareInformation from '../components/SoftwareInformation';
import { docVersion } from '../utils';

interface Props {
    isMedicalDevice?: boolean;
}

const code = `
// Programmatically using a client like Axios
await axios.post(
    'https://api-sandbox.eql.ai/v1/referral',
    {
        patient: {
            first_name: '',
            last_name: '',
            dob: '12-09-2020',
            email: 'test@eql.ai',
            phone: '',
            date_of_accident: '12-09-2020',
        },
        referrer: {
            referee_id: '',
            name: '',
        },
    },
    {
        headers: {
            Authorization: 'Bearer <token>',
        },
    },
);`;

export default function Home(props: Props) {
    const { isMedicalDevice } = props;
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    return (
        <AppShell
            styles={{
                main: {
                    background:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
            }}
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            fixed
            navbar={
                <Navbar
                    p="md"
                    hiddenBreakpoint="sm"
                    hidden={!opened}
                    width={{ sm: 200, lg: 300 }}
                >
                    <Navbar.Section mt="xs">
                        <Brand />
                    </Navbar.Section>
                    <Navbar.Section grow mt="md">
                        <MainLinks isMedicalDevice={isMedicalDevice} />
                    </Navbar.Section>
                </Navbar>
            }
            aside={
                isMedicalDevice ? (
                    <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                        <Aside
                            p="md"
                            hiddenBreakpoint="sm"
                            width={{ sm: 200, lg: 300 }}
                        >
                            <SoftwareInformation />
                        </Aside>
                    </MediaQuery>
                ) : undefined
            }
            footer={
                <Footer height={60} p="md">
                    Copyright &copy; {new Date().getFullYear()} Phio - v
                    {`${docVersion()}`}
                </Footer>
            }
            header={
                <Header height={70} p="md">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <MediaQuery
                            largerThan="sm"
                            styles={{ display: 'none' }}
                        >
                            <Burger
                                opened={opened}
                                onClick={() => setOpened((o) => !o)}
                                size="sm"
                                color={theme.colors.gray[6]}
                                mr="xl"
                            />
                        </MediaQuery>
                        <Group
                            sx={{ height: '100%' }}
                            px={20}
                            position="apart"
                            spacing="lg"
                        >
                            <Title order={2}>EQL</Title>
                        </Group>
                    </div>
                </Header>
            }
        >
            <Title order={2}>API DOCUMENTATION</Title>
            <Divider />
            <br />
            <Text>
                Phio's REST API is a back-end, microservice feature organised
                around the principles of REST (Representational State Transfer)
                - a method of fast, secure, web-based communication which allows
                Phio's internal systems to communicate with programs used by
                external organisations.
            </Text>
            <br />
            <Title order={3}>About the APIs</Title>
            <Divider />
            <br />
            <Text>
                The primary purpose of Phio Access Plus API’s is intended to
                provide Secondary Users access to the information gathered from
                the Primary user about their MSK pain, injury or dysfunction and
                the referral care pathway outcome.
            </Text>
            <br />
            <Title order={3}>How to use the API</Title>
            <Text>
                Use the APIs provided to pull data for any patients that have
                interacted with Phio Access Plus. You will be provided with an
                API Key on account creation. This <b>key</b> gives you access to
                our APIs.
            </Text>
            <br />
            <Title order={3}>Connecting to the sandbox</Title>
            <Text>
                We have a version of our API in a sandbox enviromment that you
                can use to test and or learn how our system works before you
                implement your process. You can connect using any tool of your
                choice; below are some examples:
            </Text>
            <br />
            <List>
                <List.Item>Postman</List.Item>
                <List.Item>Programmatically in a NodeJS environment</List.Item>
            </List>
            <br />
            <img
                style={{ width: 860 }}
                alt="Postman example"
                src="https://firebasestorage.googleapis.com/v0/b/phio-dev-c5d2d.appspot.com/o/misc%2Fpostman_connect.png?alt=media&token=d642c6f1-ef64-4689-b9f3-7669f1d62045"
            />
            <br />
            <Prism language="javascript">{code}</Prism>
            <Title order={3}>When to use the APIs</Title>
            <Text>
                Use these APIs when a patient has interactedwith Phio Access
                Plus so that you can access the conversation transcript and the
                referral care pathway outcome.
            </Text>
            <br />
            <Title order={3}>Our Policies</Title>
            <Text>
                Our policies tell you important information about using our
                APIs.
            </Text>
        </AppShell>
    );
}

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MantineProvider, ColorSchemeProvider } from '@mantine/core';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import './App.css';
import Home from './containers/Home';
import ApiReference from './containers/ApiReference';
import { isLocalHost, isStaging } from './utils';

const configs = {
    prod: {
        apiKey: 'AIzaSyBhBIFb2tF82x0YWa6TmkspA7khQS0Udw0',
        authDomain: 'phio-main.firebaseapp.com',
        databaseURL: 'https://phio-main.firebaseio.com',
        projectId: 'phio-main',
        storageBucket: 'phio-main.appspot.com',
        messagingSenderId: '269752456677',
        appId: '1:269752456677:web:9584326099de318a',
        measurementId: 'G-9KRV02FYP4',
    },
    staging: {
        apiKey: 'AIzaSyA9pOswwGAtpQEsUezRGBI92NctDuRQzuA',
        authDomain: 'phio-dev-c5d2d.firebaseapp.com',
        databaseURL: 'https://phio-dev-c5d2d.firebaseio.com',
        projectId: 'phio-dev-c5d2d',
        storageBucket: 'phio-dev-c5d2d.appspot.com',
        messagingSenderId: '831737646743',
        appId: '1:831737646743:web:24debf76e0470679',
        measurementId: 'G-M6N1FSPCZ7',
    },
};

const firebaseConfig =
    isStaging() || isLocalHost() ? configs.staging : configs.prod;

const fbApplication = initializeApp(firebaseConfig);

export const fbDB = getFirestore(fbApplication);

function App() {
    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{ colorScheme: 'light' }}
        >
            <ColorSchemeProvider
                colorScheme="light"
                toggleColorScheme={() => {}}
            >
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                            path="/medical-device"
                            element={<Home isMedicalDevice />}
                        />
                        <Route
                            path="/version-1-docs"
                            element={<ApiReference />}
                        />
                        <Route
                            path="/version-1-docs-medical-device"
                            element={<ApiReference isMedicalDevice />}
                        />
                    </Routes>
                </BrowserRouter>
            </ColorSchemeProvider>
        </MantineProvider>
    );
}

export default App;

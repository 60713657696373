import React, { useEffect } from 'react';
import { Stack, Text, UnstyledButton, Group, Divider } from '@mantine/core';
import { doc, getDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import MedicalDeviceLogo from '../assets/medical_device.svg';
import VersionReferenceLogo from '../assets/version.svg';
import ReleaseReferenceLogo from '../assets/release.svg';
import ManufacturerLogo from '../assets/manufacturer.svg';
import { fbDB } from '../App';

const SoftwareInformation = () => {
    const [version, setVersion] = React.useState('');
    const [releaseDate, setReleaseDate] = React.useState('');

    useEffect(() => {
        (async () => {
            const ref = doc(fbDB, 'medical_device_settings', 'versions');
            const versionDoc = await getDoc(ref);
            const versionStr = versionDoc.data()?.current_version;
            setVersion(versionStr);
            setReleaseDate(
                dayjs(versionDoc.data()?.updated_at.toDate()).format(
                    'D/M/YYYY',
                ),
            );
        })();
    }, []);

    return (
        <Stack>
            <Text variant="gradient">Software Information</Text>
            <Divider />
            <UnstyledButton>
                <Text sx={{ fontWeight: 'bold' }}>PHIO ACCESS PLUS</Text>
            </UnstyledButton>
            <UnstyledButton>
                <Group>
                    <img src={MedicalDeviceLogo} alt="Medical device" />
                    <Text>Medical Device</Text>
                </Group>
            </UnstyledButton>
            <UnstyledButton>
                <Group>
                    <img src={VersionReferenceLogo} alt="version" />
                    <Text>Version {version}</Text>
                </Group>
            </UnstyledButton>
            <UnstyledButton>
                <Group>
                    <img src={ReleaseReferenceLogo} alt="release" />
                    <Text>Release Date: {releaseDate}</Text>
                </Group>
            </UnstyledButton>
            <UnstyledButton>
                <Group>
                    <img src={ManufacturerLogo} alt="manufacturer" />
                    <Text>
                        Manufacturer
                        <br />
                        <b>
                            EQL
                            <br />
                            16 Eaton Ave
                            <br />
                            Buckshaw Village
                            <br />
                            Chorley
                            <br />
                            PR7 7NAQ
                        </b>
                    </Text>
                </Group>
            </UnstyledButton>
        </Stack>
    );
};

export default SoftwareInformation;

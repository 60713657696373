import React from 'react';
import { RedocStandalone } from 'redoc';
import {
    AppShell,
    Burger,
    Header,
    MediaQuery,
    useMantineTheme,
    Group,
    Title,
    ActionIcon,
    Image,
} from '@mantine/core';
import doc from '../openapi/dev_openapi.json';
import { isLocalHost, isStaging, docVersion } from '../utils';
import MedicalDeviceLogo from '../assets/medical_device.svg';

interface Props {
    isMedicalDevice?: boolean;
}

const devDescription =
    '# Introduction\nThis is a `sandbox API` for Phio. To access the sandbox and its set of open endpoints you will need an `API key`. The sandbox allows potential clients access to sample data during the initial phase of development and to preview new APIs and features being worked on for Phio. **The sandbox is not an exact copy of our production environment**.  *We advise clients to only use the sandbox to learn our API after which they have to use production endpoints for further development work.*\n## Rate Limiting\nWe are introducing **Rate Limiting** to our sandbox APIs, users will initially  have a limit of 100 calls per day. Please reach out if you need this limit increased for your development.';

const ApiReference = (props: Props) => {
    const { isMedicalDevice } = props;
    const [opened, setOpened] = React.useState(false);
    const theme = useMantineTheme();
    const getSpec = () => {
        const appVersion = docVersion();
        if (isLocalHost() || isStaging()) {
            return {
                ...doc,
                info: {
                    ...doc.info,
                    description: devDescription,
                    title: 'Phio API - Sandbox',
                    version: appVersion,
                },
            };
        }
        return {
            ...doc,
            info: {
                ...doc.info,
                version: appVersion,
            },
        };
    };
    return (
        <AppShell
            header={
                <Header height={70} p="md">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <MediaQuery
                            largerThan="sm"
                            styles={{ display: 'none' }}
                        >
                            <Burger
                                opened={opened}
                                onClick={() => setOpened((o) => !o)}
                                size="sm"
                                color={theme.colors.gray[6]}
                                mr="xl"
                            />
                        </MediaQuery>
                        <Group
                            sx={{ height: '100%', width: '100%' }}
                            px={20}
                            position="apart"
                            spacing="lg"
                        >
                            <Title order={2}>API Reference</Title>
                            {isMedicalDevice && (
                                <ActionIcon>
                                    <Image
                                        alt="medical device"
                                        src={MedicalDeviceLogo}
                                        width={64}
                                    />
                                </ActionIcon>
                            )}
                        </Group>
                    </div>
                </Header>
            }
        >
            <RedocStandalone spec={getSpec()} />
        </AppShell>
    );
};

export default ApiReference;
